import { connectHOCs } from "@components-utils";
import {
  STOCK_STATUS_AVAILABLE,
  STOCK_STATUS_LIMITED,
  STOCK_STATUS_OUT
} from "@constants";
import { ProductStockStatusBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";

const getStockStatusLabel = ({ inStock, balance, status, i18n }) => {
  // see Graphql ProductStockStatus enum
  let statusClass = null;
  let statusText = null;

  if (STOCK_STATUS_LIMITED === status) {
    if (balance > 0) {
      statusClass = "available";
      statusText = i18n.LABEL_AVAILABILITY_IN_STOCK;
    } else {
      statusClass = "limited";
      statusText = i18n.LABEL_AVAILABILITY_OUT_OF_STOCK;
    }
  } else if (STOCK_STATUS_AVAILABLE === status) {
    statusClass = "available";
    statusText = i18n.LABEL_AVAILABILITY_IN_STOCK;
  } else if (STOCK_STATUS_OUT === status) {
    statusClass = "out-of-stock";
    statusText = i18n.LABEL_AVAILABILITY_LIMITED_STOCK;
  } else {
    //   // this case should not happen!
    statusClass = status;
    statusText = status;
  }

  return { statusClass, statusText };
};

const ProductStockStatus = props => {
  const { statusClass, statusText } = getStockStatusLabel({
    inStock: props.inStock,
    balance: props.balance,
    status: props.status,
    i18n: props.i18n.components.ProductAvailability
  });

  return (
    <span
      className={[ProductStockStatusBS, statusClass].filter(Boolean).join(" ")}
    >
      {statusText}
    </span>
  );
};

ProductStockStatus.propTypes = {
  status: PropTypes.oneOf([
    STOCK_STATUS_AVAILABLE,
    STOCK_STATUS_LIMITED,
    STOCK_STATUS_OUT
  ]),
  balance: PropTypes.number,
  inStock: PropTypes.bool,
  i18n: PropTypes.object
};

export default connectHOCs(ProductStockStatus, { withSite: true });

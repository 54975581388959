import { connectHOCs } from "@components-utils";
import {
  STOCK_STATUS_AVAILABLE,
  STOCK_STATUS_LIMITED,
  STOCK_STATUS_OUT
} from "@constants";
import { ProductShippingWarrantyBS } from "@style-variables";
import { escapeReact } from "@utils/react";
import PropTypes from "prop-types";
import React from "react";

export const getStockDeliveryTime = ({
  deliveryTime,
  inStock,
  balance,
  status,
  validFrom,
  i18n
}) => {
  // see Graphql ProductStockStatus enum
  let statusClass = null;
  let statusText = null;

  if (STOCK_STATUS_LIMITED === status) {
    if (balance > 0) {
      statusClass = "available";
      statusText = i18n.STOCK_DELIVERY_TIME.replace(/%DAYS%/g, deliveryTime);
    } else {
      statusClass = "limited";
      statusText = i18n.STOCK_REPLENISHMENT.replace(
        /%DAYS%/g,
        deliveryTime
      ).replace(/%DATE%/g, validFrom);
    }
  } else if (STOCK_STATUS_AVAILABLE === status) {
    statusClass = "available";
    statusText = i18n.STOCK_DELIVERY_TIME.replace(/%DAYS%/g, deliveryTime);
  } else if (STOCK_STATUS_OUT === status) {
    statusClass = "out-of-stock";
    statusText = i18n.STOCK_PREORDER;
  } else {
    //   // this case should not happen!
    statusClass = status;
    statusText = status;
  }

  return { statusClass, statusText };
};

const ProductShippingWarranty = props => {
  const i18n = props.i18n.components.Product;
  const { site_min_transit_time, site_max_transit_time } =
    props.i18n.siteSettings;

  const DELIVERY_TIME = [site_min_transit_time, site_max_transit_time]
    .filter(Boolean)
    .join("-");

  const { statusClass, statusText: deliveryTime } = getStockDeliveryTime({
    deliveryTime: DELIVERY_TIME,
    inStock: props.inStock,
    balance: props.balance,
    status: props.status,
    validFrom: props.validFrom,
    i18n
  });

  const deliveryCondition = i18n.DELIVERY_CONDITION;
  const productWarranty = i18n.PRODUCT_WARRANTY;

  return (
    <ul
      className={[ProductShippingWarrantyBS, statusClass]
        .filter(Boolean)
        .join(" ")}
    >
      <li>{escapeReact(deliveryTime)}</li>
      <li>{escapeReact(deliveryCondition)}</li>
      <li>{escapeReact(productWarranty)}</li>
    </ul>
  );
};

ProductShippingWarranty.propTypes = {
  status: PropTypes.oneOf([
    STOCK_STATUS_AVAILABLE,
    STOCK_STATUS_LIMITED,
    STOCK_STATUS_OUT
  ]),
  balance: PropTypes.number,
  inStock: PropTypes.bool,
  i18n: PropTypes.object,
  validFrom: PropTypes.string
};

export default connectHOCs(ProductShippingWarranty, { withSite: true });
